import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ToastrService} from 'ngx-toastr';

import {CognitoService, LoggedInCallback} from '../../services/cognito.service';
import {environment} from '../../../environments/environment';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PerfilModalComponent} from '../modal/perfil/perfil-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, LoggedInCallback {

  public options: FormGroup;
  public username: string;
  public password: string;
  public closeResult: string;

  constructor(private router: Router,
              private fb: FormBuilder,
              private cognitoService: CognitoService,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService,
              private modalService: NgbModal,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.options = this.fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    console.log('Estado login: ', loggedIn);
    if (loggedIn) {
      this.router.navigate(['/dashboard']);
    }
  }

  login() {
    this.spinner.show();
    this.cognitoService.login(this.username, this.password).subscribe(
      (next: any) => {
        console.log(next);
        this.spinner.hide();

        const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
        const idTokenKey = `${keyPrefix}.${this.username}.idToken`;
        const accessTokenKey = `${keyPrefix}.${this.username}.accessToken`;
        const lastUserKey = `${keyPrefix}.LastAuthUser`;
        const mesaAyudaKey = `${keyPrefix}.ma`;
        const medicoContralorKey = `${keyPrefix}.mc`;
        const administrativoKey = `${keyPrefix}.adm`;
        const perfil = `${keyPrefix}.perfil`;
        console.log(keyPrefix);

        if (!next.adm) {
          next.adm = [];
        }
        let nombrePerfil = '';
        if ((next.ma.length > 0 || next.ma.length === 0) && next.mc.length === 0 && next.adm.length === 0) {
          nombrePerfil = 'Mesón de Atención';
        } else if (next.ma.length === 0 && next.mc.length > 0 && next.adm.length === 0) {
          nombrePerfil = 'Contralor';
        } else if (next.ma.length === 0 && next.mc.length === 0 && next.adm.length > 0) {
          nombrePerfil = 'Subsidios';
        }
        localStorage.setItem(idTokenKey, next.idToken);
        localStorage.setItem(accessTokenKey, next.accessToken);
        localStorage.setItem(lastUserKey, this.username);
        localStorage.setItem(mesaAyudaKey, next.ma);
        localStorage.setItem(medicoContralorKey, next.mc);
        localStorage.setItem(administrativoKey, next.adm);
        localStorage.setItem(perfil, nombrePerfil);

        if (nombrePerfil === 'Contralor' || nombrePerfil === 'Administrativo') {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/gestion-documentos']);
        }
      },
      (error: any) => {
        console.log(error);
        if (error.error === 'FORCE_CHANGE_PASSWORD') {
          this.toaster.info('Debe activar su cuenta.');
          this.router.navigate([`/activar-usuario/${this.username}`]);
        } else if (error.error.code === 'NotAuthorizedException') {
          this.toaster.error('Usuario y contraseña incorrectas.');
        } else if (error.error.code === 'PasswordResetRequiredException') {
          this.router.navigate([`/cambiar-pass-usuario/${this.username}`]);
        } else {
          this.toaster.error('Ocurrio un error al iniciar sesión.');
        }

        this.spinner.hide();
      }
    );
  }


  recuperarPass() {
    this.router.navigate([`/recuperar-pass`]);
  }

  abrirModal($event) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (null != $event) {
      dialogConfig.data = $event;

    } else {
      dialogConfig.data = null;
    }

    this.dialog.open(PerfilModalComponent, dialogConfig);
  }


}
