import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CognitoUserPool} from 'amazon-cognito-identity-js';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string): Observable<any> {
    const body = JSON.stringify({
      username,
      password
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.COGNITO}/login`, body, {headers});
  }

  activarUsuario(username: string, passwordTemporal: string, password: string): Observable<any> {
    const body = JSON.stringify({
      username,
      passwordTemporal,
      password
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.COGNITO}/activar-usuario`, body, {headers});
  }

  logout() {
    console.log('CognitoService: Cerrando sesion');
    this.getCurrentUser().signOut();
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null) {
      throw('UserLoginService: Callback en isAuthenticated() no puede ser null');
    }

    let cognitoUser = this.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function(err, session) {
        if (err) {
          console.log('UserLoginService: No se pudo recuperar la sesion: ' + err);
          callback.isLoggedIn(err, false);
        } else {
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      console.log('UserLoginService: no se pudo recuperar el CurrentUser');
      callback.isLoggedIn('No se pudo recuperar el CurrentUser', false);
    }
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getUserPool() {
    return new CognitoUserPool({
      UserPoolId: environment.USER_POOL_ID,
      ClientId: environment.CLIENT_ID
    });
  }

  recuperarPass(username: string) {
    const body = JSON.stringify({
      username
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.COGNITO}/recuperar-pass`, body, {headers});
  }

  cambiarPass(username: string, codigo: string, password: string): Observable<any> {
    const body = JSON.stringify({
      username,
      codigo,
      password
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.COGNITO}/cambio-pass-usuario`, body, {headers});
  }
}
