<div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="300"
  (scrolled)="onScroll()"
>
  <div class="col-12 card mb-3" [ngClass]=" archivo.etiquetas.length > 0?'nuevo-border-listado':''">
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="row mb-2">
          <div class="col-12 col-md-3 text-center">
            <div class="row">
              <span class="nuevo-indicador-listado font-weight-bold" *ngIf="archivo.etiquetas.length > 0">{{archivo.etiquetas[0]}}</span>
              <div class="col-10 mx-auto mt-2 img-responsive image-example-thumb-listado">
                <img src="{{archivo.url}}" class="img-thumbnail rounded mx-auto d-block" *ngIf="archivo.metadato.fileType !== 'application/pdf'">
                <img src="../../../../assets/img/pdf.png" class="img-thumbnail rounded mx-auto d-block" *ngIf="archivo.metadato.fileType === 'application/pdf'" style="height: -webkit-fill-available;width: auto;">
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 text-center pt-4">
            <h4 class="font-weight-bold">{{archivo.nombreDocumento}}</h4>
          </div>
          <!--<div *ngIf="archivo.rotulado != null" class="col-12 col-md-3 pt-4 text-center">
            <h4>{{archivo.rotulado != null ? archivo.rotulado.descripcion : ''}}</h4>
          </div>-->
          <div *ngIf="archivo.tipo_doc != null" class="col-12 col-md-3 text-center pt-3">
            <span class="badge badge-primary">{{archivo.tipo_doc != null ? archivo.tipo_doc : ''}}</span>
          </div>
          <!--<div *ngIf="archivo.rotulado == null && archivo.tipoDocumento == null" class="col-12 col-md-6 text-center mx-auto pt-3">
            <button class="btn btn-primary" (click)="open(archivo)">Rotular</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
