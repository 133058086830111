import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './componentes/dashboard/dashboard.component';
import {GestionDocumentosComponent} from './componentes/gestion-documentos/gestion-documentos.component';
import {LoginComponent} from './componentes/login/login.component';
import {ActivarUsuarioComponent} from './componentes/activar-usuario/activar-usuario.component';
import {RecuperarPassComponent} from './componentes/recuperar-pass/recuperar-pass.component';
import {CambiarPassUsuarioComponent} from './componentes/cambiar-pass-usuario/cambiar-pass-usuario.component';
import {ReporteErroresComponent} from './componentes/reporte-errores/reporte-errores.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'activar-usuario/:usuario', component: ActivarUsuarioComponent},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'gestion-documentos', component: GestionDocumentosComponent},
  { path: 'gestion-documentos/:rut', component: GestionDocumentosComponent},
  { path: 'recuperar-pass', component: RecuperarPassComponent},
  { path: 'cambiar-pass-usuario/:usuario', component: CambiarPassUsuarioComponent},
  { path: 'reporte-incidencias', component: ReporteErroresComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
