import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LicenciaModel} from '../models/licencia.model';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LicenciasService {

  constructor(private http: HttpClient) {
  }

  obtenerLicencias(subcomisiones: string, contralor: boolean): Observable<LicenciaModel[]> {
    const body = JSON.stringify({
      subcomisiones,
      contralor
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post<LicenciaModel[]>(`${environment.API_DOMAINS.LICENCIAS}/subcomision`, body, {headers})
      .pipe(map((result: any) => {
        return result.map((licencia: any) => {
          return new LicenciaModel(licencia);
        });
      }));
  }

  obtenerTrabajador(rutTrab: string): Observable<LicenciaModel[]> {
    const body = JSON.stringify({
      rutTrab
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post<LicenciaModel[]>(`${environment.API_DOMAINS.LICENCIAS}/buscarTrabajador`, body, {headers})
      .pipe(map((result: any) => {
        return result.map((licencia: any) => {
          return new LicenciaModel(licencia);
        });
      }));
  }

}
