import {Component, EventEmitter, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatFileUploadComponent, UploadFile} from '@webacad/ng-mat-file-upload';
import {DocumentoService} from '../../../services/documento.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs';
import {LicenciaModel} from '../../../models/licencia.model';
const {validate, clean, format} = require('rut.js');


class DialogData {
  id: number;
  etiquetas: any;
  nombre: string;
  rotulado: string;
  tipoDocumento: string;
  vistaPrevia: string;
  folderName: string;
  listaLicencias: [];
}

@Component({
  selector: 'app-subir-documento',
  templateUrl: './subir-documento.component.html',
  styleUrls: ['./subir-documento.component.css']
})
export class SubirDocumentoComponent implements OnInit {

  form: FormGroup;

  onAdd = new EventEmitter();
  public tipoDocumentoList = [{id: 1, descripcion: 'Administrativo'}, {id: 2, descripcion: 'Clínico'}];
  public rotuladoList = [{id: 1, descripcion: 'Cédula de identidad'}, {id: 2, descripcion: 'Parte Médico'},
    {id: 3, descripcion: 'Comprobante Pago'}, {id: 4, descripcion: 'Comprobante Visita Médico'},
    {id: 5, descripcion: 'Receta'}];
  public tipoDocSeleccion: any;

  public rotuladoSeleccion: any;

  public archivo: any;
  public archivoSubida: any;
  public data: any;

  public file: any;

  public listaLicencias: LicenciaModel[] = [];

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SubirDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private documentoService: DocumentoService) {

    this.data = data;

    if (null != data && null != data.nombre) {
      this.form = fb.group({
        nombre: [data.nombre, Validators.required],
        tipoDocumento: [data.tipoDocumento, Validators.required],
        /*folioLicencia: [archivo.folio, Validators.required]*/
      });
    } else {
      this.listaLicencias = data.listaLicencias;
      this.form = fb.group({
        nombre: ['', Validators.required],
        tipoDocumento: ['', Validators.required],
        folioLicencia: ['', Validators.required]
      });
    }
  }

  ngOnInit() {
    this.tipoDocSeleccion = {id: true, descripcion: 'Más reciente'};
  }

  save() {
    console.log(this.archivoSubida);
    this.spinner.show();
    const tipoDoc = this.tipoDocumentoList.find(tip => {
      return tip.id === this.form.controls.tipoDocumento.value;
    });
    console.log(this.form.controls.folioLicencia.value);
    this.documentoService.subirDocumento(this.archivoSubida[0].file, format(this.data.rut).split('-')[0], tipoDoc.descripcion, this.form.controls.folioLicencia.value).subscribe(
      (next: any) => {
        this.spinner.hide();
        this.toastr.success('Archivo cargado correctamente.');
        this.dialogRef.close(this.form.value);
        this.onAdd.emit();
        console.log(next);
      },
      error => {
        this.spinner.hide();
        this.toastr.error('Error a subir archivo a S3.');
        console.log(error);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  onUploadClicked($event) {
    console.log($event);
  }

  onSelectedFilesChanged($event) {
    console.log($event);
  }

  public onFileChange(fileUpload: MatFileUploadComponent): void {
    const files = fileUpload.files;
    this.archivoSubida = files;
    if (!files.length) {
      return;
    }

    /*const stepSize: number = 10;

    this.uploadFile(files[0].file, () => {
        files[0].increaseProgress(stepSize);
    }, () => {
        files[0].progress = 100;
        fileUpload.disabled = true;
    });*/
  }

  private uploadFile(file: File, onChunk: () => void, onDone: () => void): void {
    // todo
  }

  public translateValue(files: Array<UploadFile>): string {
    if (!files.length) {
      return '';
    }

    if (files.length === 1) {
      return '1 file';
    }

    return `${files.length} files`;
  }
}
