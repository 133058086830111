<!--<div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>-->
<!--<scrollable-component (scroll)="doSomethingOnScroll($event)">-->
  <div class="col-12 mx-auto card" [ngClass]="!archivo.etiquetas && archivo.etiquetas.length > 0?'nuevo-border':''">
    <div class="row">
      <div class="col-12 text-center mx-auto">
        <div class="row">
          <span class="nuevo-indicador font-weight-bold" *ngIf="!archivo.etiquetas && archivo.etiquetas.length > 0">{{archivo.etiquetas[0]}}</span>
          <div class="col-10 mx-auto mt-3 img-responsive image-example-thumb">
            <img src="{{archivo.url}}" class="img-thumbnail rounded mx-auto d-block" *ngIf="archivo.metadato.fileType !== 'application/pdf'">
            <img src="../../../../assets/img/pdf.png" class="img-thumbnail rounded mx-auto d-block" *ngIf="archivo.metadato.fileType === 'application/pdf'" style="height: -webkit-fill-available;width: auto;">
          </div>
        </div>
      </div>
      <div class="col-12 text-center mx-auto pt-2">
        <span class="font-weight-bold">{{archivo.nombreDocumento}}</span>
      </div>
      <!--<div *ngIf="archivo.rotulado != null" class="col-12 text-center mx-auto">
        <h4>{{archivo.rotulado.descripcion != null ? archivo.rotulado.descripcion : ''}}</h4>
      </div>-->
      <div *ngIf="archivo.metadato != null" class="col-12 text-center mx-auto mb-3">
        <span class="badge badge-primary">{{(archivo.metadato.tipo_doc != null ? archivo.metadato.tipo_doc : '') | titlecase}}</span>
      </div>
      <!--<div *ngIf="archivo.rotulado == null && archivo.tipoDocumento == null" class="col-12 text-center mx-auto mb-4">
        <button class="btn btn-primary" (click)="open(archivo)">Rotular</button>
      </div>-->
    </div>
  </div>
<!--</scrollable-component>-->
<!--</div>-->
