import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {LicenciaModel} from '../models/licencia.model';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IncidenciaService {

  constructor(private http: HttpClient) { }

  reportar(descripcion: string, archivo: string): Observable<any> {
    const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;
    const usuario = localStorage.getItem(lastUserKey);

    const body = JSON.stringify({
      descripcion,
      archivo,
      usuario
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.INCIDENCIA}/cargar-incidencia`, body, {headers});
  }
}
