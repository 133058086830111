<cmp-header></cmp-header>
<main>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-12 card mt-3 pt-3 pl-5 pr-5">
          <div class="row">
            <div class="col-md-6 col-12 pl-0">
              <h3>Buscar Ciudadano:</h3>
              <p>Ingresa el RUT del ciudadano para ver su documentación:</p>
            </div>
            <div class="col-md-6 col-12 pr-0 text-right float-right">
              <div class="row">
                <div class="col-md-6 col-12 form-group">
                  <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                    <mat-label>RUT del Ciudadano</mat-label>
                    <!--<input matInput placeholder="11.111.111-1" simpleMask="99.999.999-9" [addPatterns]="regexRut" [formControl]="rutFormControl" rutvalidator>-->
                    <input matInput placeholder="11.111.111-1" formatRut [formControl]="rutFormControl" rutvalidator>
                    <!--<mat-error *ngIf="rutFormControl.hasError('pattern')">
                      Ingresse un Rut Válido
                    </mat-error>-->
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-12 pt-3">
                  <button (click)="buscar()"  class="btn btn-primary mr-2"><img src="../assets/img/buscar.svg" class="mr-2">Buscar</button>
                  <button (click)="limpiar()" class="btn btn-default"><img src="../assets/img/limpiar.svg" class="mr-2">Limpiar</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 card mt-3 pt-3 pb-3 pl-5 pr-5">
          <div class="row">
            <div class="row col-12 separator pb-3 p-0 pr-0" *ngIf="null != ciudadano">
              <div class="col-10">
                <p>Estás viendo al ciudadano:</p>
                <h3><span class="font-weight-bold mr-3">{{ciudadano | rut}}</span>{{nombreCiudadano ? nombreCiudadano : ''}}</h3>
              </div>
              <div class="col-2 p-0" *ngIf="perfil !== 'Mesa Ayuda'">
                <button class="btn btn-primary text-right float-right" (click)="volver()"><img src="../assets/img/volver.svg" class="mr-2">Volver</button>
              </div>
            </div>
            <div class="col-12 text-right float-right mt-3 pr-0" *ngIf="btnUploadShow">
              <!--<button class="btn btn-primary" (click)="openDialog(null, true)"><img src="../assets/img/subir.svg" class="mr-2">Subir Documento</button>-->
              <button class="btn btn-primary" [disabled]="!ciudadano" (click)="openDialog(null)"><img src="../assets/img/subir.svg" class="mr-2">Subir Documento</button>
            </div>
            <div class="col-12 p-0 pr-0 pt-3">
              <div class="row">
                <div class="col-md-3 col-sm-6 col-12 form-group">
                  <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                    <mat-label>Buscar Documentos</mat-label>
                    <input matInput placeholder="Buscar Documentos">
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-6 col-12 form-group">
                  <a href="#" class="btn btn-primary mr-2 mt-3"><img src="../assets/img/buscar.svg" class="mr-2">Buscar</a>
                  <a href="#" class="btn btn-default mt-3"><img src="../assets/img/limpiar.svg" class="mr-2">Limpiar</a>
                </div>
                <div class="col-md-3 col-sm-6 col-12 form-group">
                  <h4 class="mt-3">Visualización:
                    <a class="mr-2" (click)="onCambioVista(1)">
                      <img src="../assets/img/cuadricula-{{displayMode == 1? 'on': 'off'}}.svg" data-toggle="tooltip"
                           data-title="Vista Cuadrícula">
                    </a>
                    <a class="mr-2" (click)="onCambioVista(2)">
                      <img src="../assets/img/listado-{{displayMode == 2? 'on': 'off'}}.svg" data-toggle="tooltip"
                           data-title="Vista Listado">
                    </a>
                  </h4>
                </div>
                <div class="col-md-3 col-sm-6 col-12 form-group">
                  <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                    <mat-label>Ordenar por:</mat-label>
                    <mat-select [(ngModel)]="ordenSeleccionado" name="orden">
                      <mat-option *ngFor="let orden of selectOrden" [value]="orden">
                        {{orden.descripcion}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row" *ngIf="listaArchivos.length >= 1">
                <div class="col-12 mt-3">
                  <h2>Listado de Documentos subidos:</h2>
                </div>
              </div>
              <div class="row" *ngIf="listaArchivos.length == 0">
                <div class="col-12 mt-3">
                  <h2>El Ciudadano consultado, no tiene documentos cargados.</h2>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 pr-0">
              <!-- grid -->
              <div *ngIf="displayMode === 1" class="search-results"
                   infiniteScroll
                   [infiniteScrollDistance]="2"
                   [infiniteScrollUpDistance]="1.5"
                   [infiniteScrollThrottle]="50"
                   (scrolled)="onScrollDown()"
                   (scrolledUp)="onScrollUp()">
                <!--<app-cmp-view-grilla [listaArchivos]="listaArchivos"></app-cmp-view-grilla>-->
                <div class="row">
                  <ng-container class="col-xs-12 col-md-3" *ngFor="let archivo of listaArchivos | orderBy: 'nombreDocumento' : ordenSeleccionado.id">
                    <app-cmp-view-grilla class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
                                     [archivo]="archivo" (openModal)="openDialog($event)" (click)="openLightbox(archivo)"></app-cmp-view-grilla>
                  </ng-container>
                </div>
              </div>

              <!-- List -->
              <div class="row mt-2" *ngIf="displayMode === 2">
                <div class="col-12 mx-auto">
                  <div class="row">
                    <div class="col-md-3 d-md-block text-center">
                      <h4 class="font-weight-bold">Vista Previa</h4>
                    </div>
                    <div class="col-md-3 d-md-block text-center">
                      <h4 class="font-weight-bold">Nombre Archivo</h4>
                    </div>
                    <!--<div class="col-md-3 d-md-block text-center">
                      <h4 class="font-weight-bold">Rotulado</h4>
                    </div>
                    <div class="col-md-3 d-md-block text-center">
                      <h4 class="font-weight-bold">Tipo Documento</h4>
                    </div>-->
                  </div>
                </div>
                <app-cmp-view-lista class="col-12"
                                *ngFor="let archivo of listaArchivos  | orderBy: 'nombre' : ordenSeleccionado.id"
                                [archivo]="archivo" (openModal)="openDialog($event)" (click)="openLightbox(archivo)"></app-cmp-view-lista>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</main>

<!--<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Date of birth</label>
        <div class="input-group">
          <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>


<ng-template #subirDocumentos let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-dos">Subir Documentos</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Date of birth</label>
        <div class="input-group">
          <input id="dateOfBirth-dos" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>-->
