import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

import {CognitoService, LoggedInCallback} from "../../services/cognito.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-activar-usuario',
    templateUrl: './activar-usuario.component.html',
    styleUrls: ['./activar-usuario.component.css']
})
export class ActivarUsuarioComponent implements OnInit, LoggedInCallback {

    options: FormGroup;
    username: string;
    passwordTemporal: string;
    password: string;
    passwordRepetir: string;

    constructor(private router: Router,
                private fb: FormBuilder,
                private cognitoService: CognitoService,
                private spinner: NgxSpinnerService,
                private toaster: ToastrService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.spinner.show();
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });

        this.cognitoService.isAuthenticated(this);
    }

    isLoggedIn(message: string, loggedIn: boolean): void {
        this.spinner.hide();
        if (loggedIn) {
            this.router.navigate(['/dashboard']);
        } else {
            this.route.params.subscribe((pathParams) => {
                console.log(pathParams);
                if (pathParams.usuario) {
                    this.username = pathParams.usuario;
                }
            });
        }
    }

    cambiarPassword() {
        if (!this.passwordTemporal || !this.password || !this.passwordRepetir) {
            this.toaster.warning('Debe completar todos los campos.');
        } else if (this.password !== this.passwordRepetir) {
            this.toaster.warning('Las nuevas contraseñas no coinciden.');
        } else {
            this.spinner.show();
            this.cognitoService.activarUsuario(this.username, this.passwordTemporal, this.password).subscribe(
                (next: any) => {
                    console.log(next);
                    this.spinner.hide();

                    const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;
                    const idTokenKey = `${keyPrefix}.${this.username}.idToken`;
                    const accessTokenKey = `${keyPrefix}.${this.username}.accessToken`;
                    const lastUserKey = `${keyPrefix}.LastAuthUser`;

                    localStorage.clear();
                    /*localStorage.setItem(idTokenKey, next.idToken);
                    localStorage.setItem(accessTokenKey, next.accessToken);
                    localStorage.setItem(lastUserKey, this.username);*/

                    this.router.navigate(['/login']);
                },
                (error: any) => {
                    console.log(error);
                    this.spinner.hide();
                    this.toaster.error('Ocurrio un error al cambiar la contraseña');
                }
            );
        }
    }

}
