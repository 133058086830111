import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(private http: HttpClient) {
  }

  getUrl(run: string, nombre: string, tipo: string): Observable<any> {
    const body = JSON.stringify({
      run,
      nombre,
      tipo
    });
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.DOCUMENTOS}/cargar`, body, {headers});
  }

  subirDocumento(file: any, rut: string, tipoDocumento: string, lmId: string): Observable<any> {
    const urlFolderName = `${environment.API_DOMAINS.FOLDER_NAME}${rut.split('-')[0]}`;

    const urlUpload = `${environment.API_DOMAINS.PRESIGNED_URL}`;
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.addEventListener('loadend', (e: any) => {
        fetch(urlFolderName, {
          method: 'GET'
        }).then((response: any) => {
          return response.json();
        }).then((folderName: any) => {
          console.log(folderName.body);
          fetch(`${environment.API_DOMAINS.DOCUMENTOS}/cargar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              run: rut.split('-')[0],
              tipoCarga: 'mesa_ayuda',
              tipoDoc: tipoDocumento,
              nombre: file.name,
              fileType: file.type,
              lmId,
              folderName : folderName.body.folder_name
            })
          }).then((response: any) => {
            return response.json();
          }).then((json) => {
            console.log('response JSon');
            console.log(json);
            fetch(json.uploadURL, {
              method: 'PUT',
              body: new Blob([reader.result], {type: file.type})
            }).then(() => {
              observer.next(file.name);
              observer.complete();
            });
          }).catch((err) => {
            observer.error(err);
            observer.complete();
          });
        }).catch((err) => {
          observer.error(err);
          observer.complete();
        });
      });
    });
  }

  requestUrlToLoadFile(file: any, run: string, nombre: string, tipo: string): Observable<any> {
    const body = JSON.stringify({
      run,
      nombre,
      tipo
    });
    const idToken = JSON.parse(localStorage.getItem('idToken'));
    const urlUpload = `${environment.API_DOMAINS.DOCUMENTOS}/cargar`;
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.addEventListener('loadend', e => {
        fetch(`${urlUpload}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: file.name,
            type: file.type
          })
        }).then(response => {
          console.log('response.json()');
          console.log(response);
          return response.json();
        }).then(json => {
          console.log('response JSon');
          console.log(json);
          fetch(json.uploadURL, {
            method: 'PUT',
            body: new Blob([reader.result], {type: file.type})
          }).then(() => {
            observer.next(file.name);
            observer.complete();
          });
        }).catch(err => {
          observer.error(err);
          observer.complete();
        });

      });
    });
  }

  obtenerDocumentosBkp(rut: string): Observable<any> {
    const urlFolderName = `${environment.API_DOMAINS.FOLDER_NAME}${rut.split('-')[0]}`;
    return new Observable(observer => {
      fetch(urlFolderName, {
        method: 'GET'
      }).then((response: any) => {
        return response.json();
      }).then((folderName: any) => {
        fetch(`${environment.API_DOMAINS.DOCUMENTOS}/obtener`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: JSON.stringify({
            rut
          })
        }).then((data) => {
          observer.next(data);
          observer.complete();
        }).catch((err) => {
          observer.error(err);
          observer.complete();
        });
      });
    });
  }

  obtenerDocumentos(rut: string): Observable<any> {
    const body = JSON.stringify({
      rut
    });
    /*const body = JSON.stringify({
      rut: this.formatRut(rut)
    });*/
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.DOCUMENTOS}/obtener`, body, {headers});
  }

  obtenerFolderName(rut: string): Observable<any> {
    return this.http.get<any>(`${environment.API_DOMAINS.FOLDER_NAME}${rut}`);
  }

  formatRut(rut: string ): string {
    return rut.replace(/^0+/, '');
  }

  subirArchivo(file: any, bucket: string): Observable<any> {
    const body = JSON.stringify({
      bucket,
      fileType: file.type
    });
    const urlUpload = `${environment.API_DOMAINS.DOCUMENTOS}/getUrlFirmada`;
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.addEventListener('loadend', e => {
        fetch(`${urlUpload}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: JSON.stringify({
            bucket,
            type: file.type
          })
        }).then(response => {
          console.log('response.json()');
          console.log(response);
          return response.json();
        }).then(json => {
          console.log('response JSon');
          console.log(json);
          fetch(json.uploadURL, {
            method: 'PUT',
            body: new Blob([reader.result], {type: file.type})
          }).then(() => {
            observer.next(json.uuidArchivo);
            observer.complete();
          });
        }).catch(err => {
          observer.error(err);
          observer.complete();
        });

      });
    });
  }
}
