<!--<cmp-header></cmp-header>
<main>
  <div class="container-fluid">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</main>-->
<ngx-spinner
        bdColor="rgba(51, 51, 51, 0.8)"
        size="default"
        color="#fff"
        type="ball-newton-cradle"
></ngx-spinner>
<router-outlet></router-outlet>
