export const environment = {
    production: false,

    API_DOMAINS: {
        COGNITO: 'https://dev.api.milicenciamedica.cl/rest/cognito',
        LICENCIAS: 'https://dev.api.milicenciamedica.cl/rest/licencias',
        DOCUMENTOS: 'https://dev.api.milicenciamedica.cl/rest/documento',
        FOLDER_NAME: 'https://2a4b0sslxf.execute-api.us-east-2.amazonaws.com/prod/getfoldername/?rutsdv=',
        PRESIGNED_URL: 'https://s6m6f7ia0k.execute-api.us-east-2.amazonaws.com/prod/getpresigned?folder_name=',
        INCIDENCIA: 'https://dev.api.milicenciamedica.cl/rest/incidencia',
    },

    S3_BUCKET_DOCUMENTOS_INCIDENCIAS: 'documents.compin.incidencias',
    USER_POOL_ID: 'us-east-2_1UIJPJDBs',
    CLIENT_ID: '2bmd7onci15nsds81lf6g2inrb',
    CALLBACK: 'https://localhost:4400/auth'
};

