import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-cmp-view-grilla',
  templateUrl: './grilla.component.html',
  styleUrls: ['./grilla.component.css']
})
export class GrillaComponent implements OnInit, OnChanges {

/*@Input() public listaArchivos: any;*/
  @Input() public archivo: any;
  @Output() public openModal: EventEmitter<any> = new EventEmitter();

  /*private finishPage = 5;
  private actualPage: number;*/

  constructor() {
    /*this.actualPage = 1;*/
  }

  ngOnInit() {
    /*console.log(this.listaArchivos);*/
  }

  open(archivo: any) {
    this.openModal.emit(archivo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*console.log('cambio');
    console.log(changes);*/
  }

}
