<!--<main>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-12 form-group">
          <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
            <mat-label>RUT del Ciudadano</mat-label>
            <input matInput placeholder="11111111-1">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</main>-->

<main>
  <div class="container-fluid background-nav" style="min-height: 100vh;">
    <div class="container">
      <div class="row">
        <div class="col-10 col-md-6 col-xl-4 card mt-3 pt-3 pb-3 mx-auto">
          <div class="row">
            <div class="col-md-12 text-center mb-4">
              <img src="../assets/img/logo-negro.svg" class="text-center" style="margin-top:-20px;">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h2>Iniciar Sesión:</h2>
              <!--<p>Ingresa tus datos para iniciar sesión:</p>-->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
          <mat-label>Usuario</mat-label>
          <input matInput placeholder="usuario" [(ngModel)]="username">
              </mat-form-field>
            </div>
            <div class="col-md-12 form-group">

              <mat-form-field appearance="fill" class="col-md-12 pl-0 pr-0">
                <mat-label>Contraseña</mat-label>
          <input type="password" matInput placeholder="********" [(ngModel)]="password">
              </mat-form-field>

            </div>
            <div class="col-md-12">
              <div class="form-check">
                <form class="example-container" [formGroup]="options">
                  <mat-checkbox formControlName="hideRequired">Recordar datos</mat-checkbox>
                </form>
              </div>
            </div>
            <div class="col-md-12 text-center mt-3 mb-2">
              <button md-raised-button class="btn btn-primary mr-2" (click)="login()">Iniciar Sesión</button>
            </div>
            <div class="col-md-12 text-center mt-3 mb-1">
              <button (click)="recuperarPass()" href="#" class="link font-weight-bold">Olvidé mi Contraseña</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
