<h2 mat-dialog-title>Subir Documento</h2>
<mat-dialog-content [formGroup]="form" style="width: 400px;">
  <div class="row" *ngIf="data.subida">
    <mat-form-field class="col-12">
      <wa-mat-file-upload #fileUpload="waMatFileUpload"
                          [preview]="false"
                          color="primary"
                          (change)="onFileChange(fileUpload)">Seleccione Archivo</wa-mat-file-upload>
    </mat-form-field>
  </div>
  <!--<div class="col-md-12">
      <mat-file-upload
        [labelText]="'Selecciona un Archivo:'"
        [allowMultipleFiles]="false"
        [showUploadButton]="false"
        (uploadClicked)="onUploadClicked($event)"
        (selectedFilesChanged)="onSelectedFilesChanged($event)">
      </mat-file-upload>
  </div>-->
  <div class="row" *ngIf="!data.subida">
    <mat-form-field class="col-12">
      <input matInput placeholder="Nombre Documento" formControlName="nombre">
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field appearance="fill" class="col">
      <mat-label>Tipo de Documento:</mat-label>
      <mat-select placeholder="Tipo Documento" formControlName="tipoDocumento">
        <mat-option *ngFor="let tipo of tipoDocumentoList" [value]="tipo.id">
          {{tipo.descripcion}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col">
      <mat-label>Folio de Licencia:</mat-label>
      <mat-select placeholder="0-0000" formControlName="folioLicencia" name="folioLicencia">
        <mat-option *ngFor="let licencia of listaLicencias" [value]="licencia.lmId">
          {{licencia.folio}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--<mat-form-field class="col-6">
      <mat-select placeholder="Rotulado" formControlName="rotulado">
        <mat-option *ngFor="let rot of rotuladoList" [value]="rot.id">
          {{rot.descripcion}}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!--<button class="mat-raised-button" (click)="close()">Close</button>
  <button class="mat-raised-button mat-primary" (click)="save()">Save</button>-->
  <button class="btn btn-primary mr-2 mt-3" (click)="save()" [disabled]="!archivoSubida || archivoSubida.length === 0"><img src="../assets/img/buscar.svg" class="mr-2">Confirmar</button>
  <button class="btn btn-default mt-3" mat-dialog-close><img src="../assets/img/limpiar.svg" class="mr-2">Cancelar</button>
</mat-dialog-actions>
