<cmp-header></cmp-header>
<main>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-12 card mt-3 pt-3 pb-3 pl-5 pr-5">
          <div class="row">
            <div class="col-12 my-3">
              <h2>Busca Licencias</h2>
            </div>
          </div>
          <div class="row">
            <mat-form-field class="col-12">
              <input matInput (keyup)="filtrar($event.target.value)" placeholder="Ingresa texto para filtrar en el listado">
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-9 my-3">
              <h2>Listado de Licencias</h2>
            </div>
            <div class="col-3 my-3">
              <button (click)="descargarListado()" md-raised-button style="vertical-align: middle;" class="btn btn-primary">
                <i class="material-icons" style="vertical-align: bottom;">cloud_download</i> Descargar Listado</button>
            </div>
          </div>
          <mat-table [dataSource]="datos" matSort>
            <!-- Columna numFormulario -->
            <ng-container matColumnDef="folio">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Folio Licencia</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.folio}} </mat-cell>
            </ng-container>

            <!-- Columna rutTrabajador -->
            <ng-container matColumnDef="rutTrab">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Ciudadano</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.formatRut(element.rutTrab)}} </mat-cell>
            </ng-container>

            <!-- Columna nombreTrabajador -->
            <ng-container matColumnDef="nomTrab">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.nomTrab}} </mat-cell>
            </ng-container>

            <!-- Columna numServicioSalud -->
            <ng-container matColumnDef="numServicioSalud">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Subcomisión</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.numServicioSalud}} </mat-cell>
            </ng-container>

            <!-- Columna tiempoLicencia -->
            <ng-container matColumnDef="lastDatePendiente">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Pendiente en {{perfil}}</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastDatePendiente }} </mat-cell>
            </ng-container>

            <!-- Columna tiempoLicencia -->
            <ng-container matColumnDef="diferenciaDias">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Nº días pendiente</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.diferenciaDias}} </mat-cell>
            </ng-container>

            <!-- Columna documentosNuevos -->
            <ng-container matColumnDef="documentosNuevos">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Carpeta Documentos</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span style="padding-left: 30%;padding-right: 30%;">
                <mat-icon *ngIf="element.glosa.documentos >= 1" matBadge="{{element.glosa.nuevos}}" matBadgeSize="small" [matBadgeHidden]="element.glosa.nuevos == 0" matBadgeColor="warn">folder</mat-icon>
                <mat-icon *ngIf="element.glosa.documentos == 0" matBadge="{{element.glosa.nuevos}}" matBadgeSize="small" [matBadgeHidden]="true" matBadgeColor="warn">folder_open</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accion">
              <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 150px;"> Acción</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button md-raised-button class="btn btn-primary" (click)="irGestionDocumentos(element)" style="padding: 5px;"><img src="../assets/img/buscar.svg" class="mr-2">Gestionar</button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnas; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnas;"></mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10,15, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</main>

