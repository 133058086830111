<mat-toolbar class="navbar-expand-md background-nav">
  <a href="index.html" class="navbar-brand text-bold">
    <img src="../../../../assets/img/logo-minsal.svg" class="float-left" alt="Logo Ministerio de Salud">
  </a>

  <span style="flex: 1 1 auto;"></span>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-md-0 ml-auto d-md-block">
      <span class="text-left texto-blanco" data-toggle="dropdown" style="background:transparent;color:white;">
      <img src="../../../../assets/img/usuario.svg" class="mr-1">
      <span class="font-weight-bold">{{perfil}}</span><br/>
        <!--<a href="" (click)="cerrarSesion()" class="texto-blanco" style="margin-left:25px;">Salir</a>-->
      </span>
    </form>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="texto-blanco">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="redireccion(3)" *ngIf="perfil === 'Mesón de Atención'">
    <mat-icon>dashboard</mat-icon>
    <span>Gestión de Documentos</span>
  </button>
  <button mat-menu-item (click)="redireccion(4)" *ngIf="perfil === 'Contralor' || perfil === 'Subsidios'">
    <mat-icon>dashboard</mat-icon>
    <span>Dashboard</span>
  </button>
  <button mat-menu-item (click)="redireccion(1)" *ngIf="reporte">
    <mat-icon>bug_report</mat-icon>
    <span>Reporte de Incidencias</span>
  </button>
  <button mat-menu-item (click)="redireccion(2)">
    <mat-icon>exit_to_app</mat-icon>
    <span>Salir</span>
  </button>
</mat-menu>


