import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SubirDocumentoComponent} from '../modal/subir-documento/subir-documento.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DocumentoService} from '../../services/documento.service';
import {CognitoService} from '../../services/cognito.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Location} from '@angular/common';
import {forkJoin} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Lightbox, LightboxConfig} from 'ngx-lightbox';
import {FormControl, Validators} from '@angular/forms';
import {LicenciasService} from '../../services/licencias.service';
import {LicenciaModel} from '../../models/licencia.model';
import {RutValidator} from 'ng2-rut';

const {validate, clean, format, rutSinDv} = require('rut.js');

@Component({
  selector: 'app-gestion-documentos',
  templateUrl: './gestion-documentos.component.html',
  styleUrls: ['./gestion-documentos.component.css']
})
export class GestionDocumentosComponent implements OnInit {

  /*rutFormControl = new FormControl('', [Validators.pattern('^(\d{2}\.\d{3}\.\d{3}-)([a-zA-Z]{1}$|\d{1}$)')]);*/
  public rutFormControl = new FormControl('', []);

  public selectOrden = [{id: true, descripcion: 'Más reciente'}, {id: false, descripcion: 'Más antiguo'}];
  public displayMode = 1;
  public listaArchivos = [];
  public listaArchivosTotales = [];
  public listaLicencias: LicenciaModel[] = [];

  public ordenSeleccionado: any;
  public ciudadano: any;
  public closeResult: string;

  private finishPage = 10;
  private actualPage: number;
  private folderName: string;

  public btnUploadShow = true;
  public licencia: any;
  public nombreCiudadano: string;
  public perfil: string;
  public despliegueArchivos = '*';

  private albums = [];
  public regexRut: any = /^[0-9]+-[0-9kK]{1}$/;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private cognitoService: CognitoService,
              private spinner: NgxSpinnerService,
              public lightbox: Lightbox,
              public lightBoxConfig: LightboxConfig,
              public dialog: MatDialog,
              private location: Location,
              private documentoService: DocumentoService,
              private licenciaService: LicenciasService) {
    this.actualPage = 1;

    this.lightBoxConfig.albumLabel = 'Documento %1 de %2';
    this.lightBoxConfig.wrapAround = true;
    this.lightBoxConfig.showImageNumberLabel = true;
    this.lightBoxConfig.centerVertically = true;
    /*this.lightBoxConfig.fitImageInViewPort = false;*/
  }

  ngOnInit() {
    this.ordenSeleccionado = {id: true, descripcion: 'Más reciente'};
    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (loggedIn) {
      const keyStorage = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}.perfil`;
      this.perfil = localStorage.getItem(keyStorage);

      if (this.perfil === 'Contralor') {
        this.btnUploadShow = false;
        this.despliegueArchivos = 'clinico';
      } else if (this.perfil === 'Administrativo') {
        this.despliegueArchivos = 'administrativo';
      }
      this.route.params.subscribe((pathParams) => {
        if (pathParams.rut) {
          this.rutFormControl.setValue(pathParams.rut);
          this.ciudadano = pathParams.rut;
          this.obtnerFolderName();
          this.licencia = JSON.parse(localStorage.getItem('licencia'));
          if (this.licencia) {
            this.nombreCiudadano = this.licencia.nomTrab ? this.licencia.nomTrab : '';
            localStorage.removeItem('licencia');
          }
          /*this.cargarDatos(this.ciudadano);*/
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  obtnerFolderName() {
    this.spinner.show();
    this.documentoService.obtenerFolderName(format(this.ciudadano).split('-')[0]).subscribe(
      (data: any) => {
        console.log(data.body);
        this.folderName = data.body.folder_name;
        this.cargarDatos(this.folderName);
      },
      error => {
        console.log(error);
        if (error.status === 401 || error.status === 0) {

        } else {

        }
      }
    );
  }

  cargarDatos(folderName: string) {
    /*this.ciudadano = '0044444444';*/
    const subscribes = [];
    console.log(folderName);
    /*subscribes.push(this.documentoService.obtenerFolderName(rut.split('-')[0]));*/
    subscribes.push(this.documentoService.obtenerDocumentos(folderName));
    /*subscribes.push(this.licenciaService.obtenerTrabajador(this.rutFormControl.value.replace(/[.*+?^${}()|[\]\\]/g, '')));*/
    subscribes.push(this.licenciaService.obtenerTrabajador(rutSinDv(this.rutFormControl.value)));
    this.spinner.show();
    forkJoin(subscribes).subscribe(
      (result) => {
        /*console.log(result[0].body.folder_name);
        this.folderName = result[0].body.folder_name;*/

        console.log(result[0]);

        /*this.listaArchivos = result[0];*/
        this.albums = [];
        this.listaArchivos = [];
        this.listaArchivosTotales = result[0].sort((a, b) => a.timestamp > b.timestamp);
        /*this.listaArchivosTotales = result[0];*/
        this.listaArchivosTotales.forEach(archivo => {
          if (archivo.metadato && archivo.metadato.tipo_doc === this.despliegueArchivos) {
            this.listaArchivos.push(archivo);
          } else if ('*' === this.despliegueArchivos) {
            this.listaArchivos = result[0];
          }
        });

        this.listaArchivos.forEach(archivo => {
          const album = {
            src: archivo.url,
            caption: archivo.nombreDocumento,
            thumb: archivo.url,
            id: archivo.nombreDocumento,
            timestamp: archivo.timestamp
          };
          this.albums.push(album);
        });
        this.albums.sort((a, b): number => {
          if (a.timestamp < b.timestamp) { return 1; }
          if (a.timestamp > b.timestamp) { return -1; }
          return 0;
        });
        this.spinner.hide();

        console.log(result[1]);
        this.listaLicencias = result[1];
        this.nombreCiudadano = this.listaLicencias.length > 0 ? this.listaLicencias[0].nomTrab : '';
      }, error => {
        /*this.ngxService.stop();*/
        this.spinner.hide();
        console.log(error);
      });
  }

  onCambioVista(mode: number): void {
    this.displayMode = mode;
  }

  onScrollDown() {
    if (this.finishPage === this.actualPage) {
      console.log('se avanza el scroll');
      this.cargarDocumentos();
      this.actualPage = 0;
    } else {
      this.actualPage++;
      console.log('No more lines. Finish page!');
    }
  }

  onScrollUp() {
    this.actualPage--;
  }

  cargarDocumentos() {
    const lista = this.listaArchivos;
    for (let x = 0; x < 4; x++) {
      console.log(this.listaArchivos[x]);
      this.listaArchivos.push(this.listaArchivos[x]);
    }
  }

  openDialog($event): void {
    console.log($event);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (null != $event) {
      dialogConfig.data = $event;
      dialogConfig.data.subida = false;
      dialogConfig.data.rut = this.ciudadano;
    } else {
      /*dialogConfig.data = null;*/
      dialogConfig.data = {
        subida: true,
        listaLicencias: this.listaLicencias,
        rut: this.ciudadano
      };
    }

    const dialogRef = this.dialog.open(SubirDocumentoComponent, dialogConfig);

    const sub = dialogRef.componentInstance.onAdd.subscribe(() => {
      console.log('onAdd');
      /*this.cargarDatos(this.folderName);*/
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('afterClosed');
      this.cargarDatos(this.folderName);
    });
    /*
    dialogRef.afterClosed().subscribe(data => {
      console.log('cerrando');
    }, error => {
      console.log(error);
    });*/
  }

  abrirModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log('cerrar');
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openLightbox(archivo: any): void {
    // open lightbox
    if (archivo.metadato && archivo.metadato.fileType === 'application/pdf') {
      window.open(archivo.url, '_blank');
    } else {
      const index = this.albums.findIndex(record => record.id === archivo.nombreDocumento);
      /*console.log(index);*/
      /*this.lightbox.open(this.albums, index, { albumLabel: 'Documento %1 de %2', wrapAround: true, showImageNumberLabel: true });*/
      this.lightbox.open(this.albums, index);
      /*console.log(this.lightbox._documentRef.querySelectorAll('lb-outerContainer').attribute('rotacion', 90));
      console.log(this.lightbox._documentRef.querySelectorAll('lb-outerContainer').attribute('rotacion'));*/
    }

  }

  closeLightbox(): void {
    this.lightbox.close();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  buscar() {
    if (this.rutFormControl.valid) {
      this.ciudadano = this.rutFormControl.value.replace(/[.*+?^${}()|[\]\\]/g, '');
      this.obtnerFolderName();
    }
  }

  volver() {
    this.location.back();
  }

  limpiar() {
    this.rutFormControl.reset();
    this.ciudadano = null;
    this.listaArchivos = [];
  }
}
