import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CognitoService} from '../../services/cognito.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cambiar-pass-usuario',
  templateUrl: './cambiar-pass-usuario.component.html',
  styleUrls: ['./cambiar-pass-usuario.component.css']
})
export class CambiarPassUsuarioComponent implements OnInit {


  options: FormGroup;
  username: string;
  codigo: string;
  password: string;
  passwordRepetir: string;

  constructor(private router: Router,
              private fb: FormBuilder,
              private cognitoService: CognitoService,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.spinner.show();
    this.options = this.fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    this.spinner.hide();
    if (loggedIn) {
      this.router.navigate(['/dashboard']);
    } else {
      this.route.params.subscribe((pathParams) => {
        console.log(pathParams);
        if (pathParams.usuario) {
          this.username = pathParams.usuario;
        }
      });
    }
  }

  cambiarPassword() {
    if (!this.codigo || !this.password || !this.passwordRepetir) {
      this.toaster.warning('Debe completar todos los campos.');
    } else if (this.password !== this.passwordRepetir) {
      this.toaster.warning('Las nuevas contraseñas no coinciden.');
    } else {
      this.spinner.show();
      this.cognitoService.cambiarPass(this.username, this.codigo, this.password).subscribe(
        (next: any) => {
          console.log(next);
          this.spinner.hide();
          this.toaster.error('Cambio de contraseña exitoso.');
          this.router.navigate(['/login']);
        },
        (error: any) => {
          console.log(error);
          this.spinner.hide();
          this.toaster.error('Ocurrio un error al cambiar la contraseña');
        }
      );
    }
  }

}
