import {Component, OnInit} from '@angular/core';
import {MatFileUploadComponent} from '@webacad/ng-mat-file-upload';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DocumentoService} from '../../services/documento.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {IncidenciaService} from '../../services/incidencia.service';
import {CognitoService} from '../../services/cognito.service';

@Component({
  selector: 'app-reporte-errores',
  templateUrl: './reporte-errores.component.html',
  styleUrls: ['./reporte-errores.component.css']
})
export class ReporteErroresComponent implements OnInit {

  public archivoSubida: any;
  public form: FormGroup;

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder,
              private router: Router,
              private cognitoService: CognitoService,
              private documentoService: DocumentoService,
              private incidenciaService: IncidenciaService) {
    this.form = fb.group({
      descripcion: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    if (!loggedIn) {
      this.router.navigate(['/login']);
    }
  }

  public onFileChange(fileUpload: MatFileUploadComponent): void {
    const files = fileUpload.files;
    this.archivoSubida = files;
    if (!files.length) {
      return;
    }
  }

  public enviar() {
    this.spinner.show();
    if (this.form.valid) {
      console.log(this.form.value);
      if (this.archivoSubida && this.archivoSubida.length >= 1) {
        console.log('archivos');
        this.cargarArchivo();
      } else {
        console.log('sin archivos');
        this.reportar('Sin Adjunto');
      }
    } else {
      this.spinner.hide();
      this.form.markAsTouched();
      this.toastr.error('Debe al menos enviar la descripción de la incidencia.');
    }
  }

  public reportar(uuidArchivo: string) {
    this.incidenciaService.reportar(this.form.value.descripcion, uuidArchivo).subscribe(
      (next: any) => {
        console.log(next);
        this.spinner.hide();
        this.toastr.success('Incidencia reportada correctamente.');
        this.form.reset();
      },
      error => {
        console.log(error);
        this.spinner.hide();
        this.toastr.error('Error al reportar el incidente.');
      });
  }

  public cargarArchivo() {
    this.documentoService.subirArchivo(this.archivoSubida[0].file, `${environment.S3_BUCKET_DOCUMENTOS_INCIDENCIAS}`).subscribe(
      (next: any) => {
        this.spinner.hide();
        this.toastr.success('Archivo cargado correctamente.');
        this.reportar(next);
        console.log(next);
      },
      error => {
        this.spinner.hide();
        this.toastr.error('Error a subir archivo a S3.');
        console.log(error);
      }
    );
  }

}
