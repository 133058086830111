import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {TooltipModule} from 'ng2-tooltip-directive';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {OrderModule} from 'ngx-order-pipe';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './componentes/commons/header/header.component';
import {FooterComponent} from './componentes/commons/footer/footer.component';
import {LoginComponent} from './componentes/login/login.component';
import {DashboardComponent} from './componentes/dashboard/dashboard.component';
import {
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatToolbarModule,
  MatMenuModule,
  MatCheckboxModule,
  MatPaginatorIntl,
  MatSortModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDatepickerModule,
  MatBadgeModule,
  MatRadioModule
} from '@angular/material';
import {ListaComponent} from './componentes/vistas/lista/lista.component';
import {GrillaComponent} from './componentes/vistas/grilla/grilla.component';
import {GestionDocumentosComponent} from './componentes/gestion-documentos/gestion-documentos.component';
import {MatPaginatorIntlCl} from './utils/MatPaginatorIntlCl';
import {SubirDocumentoComponent} from './componentes/modal/subir-documento/subir-documento.component';
import {RotularComponent} from './componentes/modal/rotular/rotular.component';
import {MatFileUploadModule} from '@webacad/ng-mat-file-upload';
import {ActivarUsuarioComponent} from './componentes/activar-usuario/activar-usuario.component';
import {PerfilModalComponent} from './componentes/modal/perfil/perfil-modal.component';
import {LightboxModule} from 'ngx-lightbox';
/*import {NgxMaskInputModule} from 'ngx-mask-input';*/

import {RutValidatorDirective} from './directives/rut-validator.directive';
import {SimpleMaskDirective, SimpleMaskModule, SimpleMaskPipe} from 'ngx-ion-simple-mask';
import {Ng2Rut} from 'ng2-rut';
import { RecuperarPassComponent } from './componentes/recuperar-pass/recuperar-pass.component';
import { CambiarPassUsuarioComponent } from './componentes/cambiar-pass-usuario/cambiar-pass-usuario.component';
import { ReporteErroresComponent } from './componentes/reporte-errores/reporte-errores.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    ListaComponent,
    GrillaComponent,
    GestionDocumentosComponent,
    SubirDocumentoComponent,
    RotularComponent,
    PerfilModalComponent,
    ActivarUsuarioComponent,
    RutValidatorDirective,
    RecuperarPassComponent,
    CambiarPassUsuarioComponent,
    ReporteErroresComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
    }),
    NgbModule,
    TooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    OrderModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSortModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFileUploadModule,
    MatBadgeModule,
    MatRadioModule,
    InfiniteScrollModule,
    LightboxModule,
    SimpleMaskModule,
    Ng2Rut
  ],
  providers: [
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlCl},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: []}],
  bootstrap: [AppComponent],
  entryComponents: [SubirDocumentoComponent, PerfilModalComponent]
})
export class AppModule {
}

/*platformBrowserDynamic().bootstrapModule(AppModule);*/

