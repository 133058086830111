import {MetadatoDocumento} from './metadato-documento.model';
import * as moment from 'moment';

export class LicenciaModel {

  lmId: string;
  ant: [];
  CodPriContinua: string;
  derSub: string;
  diasM: string;
  diasO: string;
  edad: string;
  entidadPagadora: string;
  fin: string;
  finModif: string;
  folio: string;
  inicio: string;
  inicioModif: string;
  instancias: [];
  lastTime: string;
  lastState: string;
  lastDatePendiente: string;
  nomEmpl: string;
  nomTrab: string;
  nombreCaja: string;
  nroInstancias: string;
  numServicioSalud: string;
  procesoTipo: string;
  rutEmpl: string;
  rutPres: string;
  rutTrab: string;
  sexo: string;
  tipo: string;
  tipoEmision: string;
  glosa: any;
  documentos: MetadatoDocumento [];
  diferenciaDias: number;

  constructor(obj?: any) {
    this.lmId = obj && obj.lm_id || null;
    this.ant = obj && obj.ant || null;
    this.CodPriContinua = obj && obj.Cod_Pri_Continua || null;
    this.derSub = obj && obj.der_sub || null;
    this.diasM = obj && obj.dias_m || null;
    this.diasO = obj && obj.dias_o || null;
    this.edad = obj && obj.edad || null;
    this.entidadPagadora = obj && obj.entidad_pagadora || null;
    this.fin = obj && obj.fin || null;
    this.finModif = obj && obj.fin_modif || null;
    this.folio = obj && obj.folio || null;
    this.inicio = obj && obj.inicio || null;
    this.inicioModif = obj && obj.inicio_modif || null;
    this.instancias = obj && obj.instancias || null;
    this.lastTime = obj && obj.last_time || null;
    this.lastState = obj && obj.last_state || null;
    this.lastDatePendiente = obj && obj.last_date_pendiente && moment(obj.last_date_pendiente, 'DD-MM-YYYY').format('DD/MM/YYYY') || '';
    this.nomEmpl = obj && obj.nom_empl || null;
    this.nomTrab = obj && obj.nom_trab || null;
    this.nombreCaja = obj && obj.Nombre_Caja || null;
    this.nroInstancias = obj && obj.nro_instancias || null;
    this.numServicioSalud = obj && obj.NumServicioSalud || null;
    this.procesoTipo = obj && obj.proceso_tipo || null;
    this.rutEmpl = obj && obj.rut_empl || null;
    this.rutPres = obj && obj.rut_pres || null;
    this.rutTrab = obj && obj.rut_trab || null;
    this.sexo = obj && obj.sexo || null;
    this.tipo = obj && obj.tipo || null;
    this.tipoEmision = obj && obj.tipo_emision || null;
    this.documentos = obj && obj.documentos || [];
    this.glosa = {
      documentos: 0,
      nuevos: 0
    };
    this.diferenciaDias = 0;
  }

  formatRut(rut: string): string {
    return rut ? rut.replace(/^0+/, '') : '';
  }
}
