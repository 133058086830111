import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, FormControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[rutvalidator][formControl]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: RutValidatorDirective,
    multi: true
  }]
})
export class RutValidatorDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = this.RutValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  RutValidator(): ValidatorFn {
    return (c: FormControl) => {
      if (c.value !== '' && c.value !== null && c.value !== undefined) {
        const isValid = this.isValid(c.value.replace(/\./g, '').replace('-', ''));
        if (isValid) {
          return null;
        } else {
          return {
            rutvalidator: {
              valid: isValid
            }
          };
        }
      } else {
        return null;
      }
    };
  }

  isValid(rut: string): boolean {
    if (rut.length > 1) {
      rut = rut.substring(0, rut.length - 1) + '-' + rut.substring(rut.length - 1, rut.length);
    }

    if (!/^[0-9]+-[0-9kK]{1}$/.test(rut)) {
      return false;
    }

    const tmp = rut.split('-');

    return this.checkDV(tmp[0]) === tmp[1].toLowerCase();
  }

  checkDV(rutPartNumber: string): string {
    const secuencia = [2, 3, 4, 5, 6, 7, 2, 3];
    let s = 0;

    for (let i: number = rutPartNumber.length - 1; i >= 0; i--) {
      const d = rutPartNumber.charAt(i);
      s += parseInt(d, 10) * secuencia[rutPartNumber.length - (i + 1)];
    }

    const rest = 11 - (s % 11);

    return rest === 11 ? '0' : rest === 10 ? 'k' : rest.toString();
  }
}
