import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

class DialogData {
  id: number;
  etiquetas: any;
  nombre: string;
  rotulado: string;
  tipoDocumento: string;
  vistaPrevia: string;
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil-modal.component.html',
  styleUrls: ['./perfil-modal.component.css']
})
export class PerfilModalComponent implements OnInit {

  public listPerfil = [{
    id: 1,
    descripcion: 'Medico Contralor'
  }, {
    id: 2,
    descripcion: 'Mesón de Atención'
  }, {
    id: 3,
    descripcion: 'Subsidio'
  }];
  public seleccionPerfil: any;

  constructor(private router: Router,
              private dialogRef: MatDialogRef<PerfilModalComponent>) {
  }


  ngOnInit() {

  }

  irPortal() {
    /*if (true) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/gestion-documentos']);
    }*/
    let ruta: string;
    switch (this.seleccionPerfil) {
      case 1:
        ruta = '/dashboard';
        break;
      case 3:
        ruta = '/gestion-documentos';
        break;
      case 2:
        ruta = '/gestion-documentos';
        break;

    }
    this.dialogRef.close();
    this.router.navigate([ruta]);
  }


}
