<cmp-header></cmp-header>
<main>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-12 card mt-3 pt-3 pb-3 pl-5 pr-5">
          <div class="row">
            <div class="col-12 separator pb-3 p-0 pr-0">
              <div class="col-10">
                <h1>Información de incidente</h1>
                <p>En esta pantalla, usted podrá informarnos si detecta algun incidente en el sistema, favor explicar con la mayor cantidad de detalles, adicionalmente podrá adjuntar una imagen explicativa o captura de pantalla</p>
              </div>
            </div>

            <div class="col-12 p-0 pr-0 pt-3" [formGroup]="form">
              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0 pb-1">
                <mat-label>Identificación de Incidencia</mat-label>
                <textarea matInput placeholder="Ingrese la descripción del caso" formControlName="descripcion" [rows]="10"></textarea>
                <mat-error *ngIf="form.controls.descripcion.hasError('required')">
                  La descripción de la incidencia es requerida.
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                <wa-mat-file-upload #fileUpload="waMatFileUpload" [preview]="true" color="primary" (change)="onFileChange(fileUpload)">Seleccione Archivo</wa-mat-file-upload>
              </mat-form-field>
              <button class="btn btn-primary text-right float-right" (click)="enviar()"><img src="../assets/img/subir.svg" class="mr-2">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

