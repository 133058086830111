import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cmp-view-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.css']
})
export class ListaComponent implements OnInit {

  @Input() public archivo: any;
  @Output() openModal: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  open(archivo: any) {
    this.openModal.emit(archivo);
  }

  onScroll() {
    console.log('scrolled!!');
  }

}
