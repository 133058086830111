import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'cmp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public perfil: string;
  public reporte = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    const keyStorage = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}.perfil`;
    console.log(keyStorage);
    this.perfil = localStorage.getItem(keyStorage);
    const usuario = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.CLIENT_ID}.LastAuthUser`)
    if ('hernan.castro' === usuario || 'pablo.umana' === usuario || 'juan.rodriguez' === usuario) {
      this.reporte = true;
    }
  }

  cerrarSesion() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  redireccion(destino: number) {
    switch (destino) {
      case 1: /*Reporte de incidencias*/
        this.router.navigate(['/reporte-incidencias']);
        break;
      case 2: /*salir*/
        localStorage.clear();
        this.router.navigate(['/login']);
        break;
      case 3:
        this.router.navigate(['/gestion-documentos']);
        break;
      case 4:
        this.router.navigate(['/dashboard']);
        break;
    }

  }

}
