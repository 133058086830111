<main>
  <div class="container-fluid background-nav">
    <div class="container">
      <div class="row">
        <div class="col-10 col-md-6 col-xl-4 card mt-3 pt-3 pb-3 mx-auto">
          <div class="row">
            <div class="col-md-12 text-center mb-4">
              <img src="../assets/img/logo-negro.svg" class="text-center" style="margin-top:-20px;">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h2>Activar cuenta:</h2>
              <p>Ingresa tus datos para activar su cuenta e iniciar sesión:</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                <mat-label>Usuario</mat-label>
                <input matInput placeholder="usuario" [(ngModel)]="username" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 form-group">

              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                <mat-label>Contraseña Temporal</mat-label>
                <input type="password" matInput placeholder="********" [(ngModel)]="passwordTemporal">
              </mat-form-field>

            </div>
            <div class="col-12 form-group">

              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                <mat-label>Nueva Contraseña</mat-label>
                <input type="password" matInput placeholder="********" [(ngModel)]="password">
              </mat-form-field>

            </div>
            <div class="col-12 form-group">

              <mat-form-field appearance="fill" class="col-12 pl-0 pr-0">
                <mat-label>Repetir Nueva Contraseña</mat-label>
                <input type="password" matInput placeholder="********" [(ngModel)]="passwordRepetir">
              </mat-form-field>

            </div>
            <div class="col-md-12 text-center mt-3 mb-2">
              <button md-raised-button class="btn btn-primary mr-2" (click)="cambiarPassword()">Cambiar Contraseña
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
