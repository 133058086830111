import {Component, Renderer2} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Compin';

    previousUrl: string;

    constructor(private renderer: Renderer2, private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                console.log(this.previousUrl)
                if (event.url == '/login') {
                    this.renderer.addClass(document.body, 'background-nav');
                } else {
                    this.renderer.removeClass(document.body, 'background-nav');
                    this.renderer.addClass(document.body, 'body-main');
                }
                /*if (this.previousUrl) {
                  this.renderer.removeClass(document.body, this.previousUrl);
                }
                let currentUrlSlug = event.url.slice(1)
                if (currentUrlSlug) {
                  this.renderer.addClass(document.body, 'background-nav');
                }
                this.previousUrl = currentUrlSlug;*/
            }
        });
    }
}
