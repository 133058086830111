<h2 mat-dialog-title>Seleccione Perfil</h2>
<mat-dialog-content>
  <div class="row">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="seleccionPerfil">
      <mat-radio-button *ngFor="let op of listPerfil" [value]="op.id">{{op.descripcion}}</mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="btn btn-primary mr-2 mt-3" (click)="irPortal()"><img src="../assets/img/buscar.svg" class="mr-2">Confirmar</button>
  <button class="btn btn-default mt-3" mat-dialog-close><img src="../assets/img/limpiar.svg" class="mr-2">Cancelar</button>
</mat-dialog-actions>

