import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CognitoService, LoggedInCallback} from '../../services/cognito.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.css']
})
export class RecuperarPassComponent implements OnInit, LoggedInCallback {


  public options: FormGroup;
  public username: string;
  public password: string;
  public closeResult: string;

  constructor(private router: Router,
              private fb: FormBuilder,
              private cognitoService: CognitoService,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.options = this.fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    this.cognitoService.isAuthenticated(this);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    console.log('Estado login: ', loggedIn);
    if (loggedIn) {
      this.router.navigate(['/dashboard']);
    }
  }

  login() {
    this.spinner.show();
    this.cognitoService.recuperarPass(this.username).subscribe(
      (next: any) => {
        console.log(next);
        this.spinner.hide();
        this.toaster.info('Se ha reestablecido su contraseña, recibirá un correo electónico con los pasos a seguir.');
        this.router.navigate(['/login']);
      },
      (error: any) => {
        console.log(error);
        if (error.error.code === 'UserNotFoundException') {
          this.toaster.error('Usuario no existe, favor contactarse con administrador.');
        } else {
          this.toaster.error('Ocurrio un error al intentar recuperar contraseña.');
        }

        this.spinner.hide();
      }
    );
  }
}
