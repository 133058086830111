import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';

import {NgxSpinnerService} from 'ngx-spinner';

import {CognitoService, LoggedInCallback} from '../../services/cognito.service';
import {LicenciasService} from '../../services/licencias.service';
import {LicenciaModel} from '../../models/licencia.model';

import * as moment from 'moment';
import 'moment/locale/es';

import {environment} from '../../../environments/environment';
import {ExportacionService} from '../../services/exportacion.service';

const {validate, clean, format} = require('rut.js');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, LoggedInCallback {

  public listaLicencias: LicenciaModel[];

  public columnas: string[] = ['folio', 'rutTrab', 'nomTrab', 'numServicioSalud', 'lastDatePendiente', 'diferenciaDias', 'documentosNuevos', 'accion'];

  public datos: MatTableDataSource<any>;
  public ordenSeleccionado: any;
  public selectOrden = [{id: true, descripcion: 'Más reciente'}, {id: false, descripcion: 'Más antiguo'}];

  @ViewChild(MatPaginator, {static: true}) paginador: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  closeResult: any;
  public perfil: string;

  constructor(private router: Router,
              private cognitoService: CognitoService,
              private spinner: NgxSpinnerService,
              private licenciasService: LicenciasService,
              private exportacionService: ExportacionService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.listaLicencias = [];
    this.cognitoService.isAuthenticated(this);
  }

  /**
   * Metodo que se encarga de filtrar los resultados de la grilla.
   *
   * @param valor
   */
  filtrar(valor: string) {
    this.datos.filter = valor.trim().toLowerCase();
  }

  /**
   * Metodo que se encarga de redireccionar al gestor de documentos.
   *
   * @param registro
   */
  irGestionDocumentos(registro: LicenciaModel) {
    console.log(registro.nomTrab);
    console.log(clean(registro.rutTrab));
    console.log(validate(registro.rutTrab));
    console.log(format(registro.rutTrab, true));
    console.log(format(registro.rutTrab, false));
    localStorage.setItem('licencia', JSON.stringify(registro));
    this.router.navigate(['/gestion-documentos/' + format(registro.rutTrab, false)]);
  }

  isLoggedIn(message: string, loggedIn: boolean): void {
    console.log('Estado login: ', loggedIn);
    if (loggedIn) {
      this.spinner.show();
      const keyStorage = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}.perfil`;
      this.perfil = localStorage.getItem(keyStorage);
      let subcomisiones = '';
      let contralor = false;
      const keyStorageMC = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}.mc`;
      const keyStorageMA = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}.ma`;
      const keyStorageADM = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}.adm`;

      if (localStorage.getItem(keyStorageMC)) {
        contralor = true;
        subcomisiones = localStorage.getItem(keyStorageMC);
      } else if (localStorage.getItem(keyStorageMA)) {
        subcomisiones = localStorage.getItem(keyStorageMA);
        this.spinner.hide();
        return;
      } else if (localStorage.getItem(keyStorageADM)) {
        subcomisiones = localStorage.getItem(keyStorageADM);
      }
      this.licenciasService.obtenerLicencias(subcomisiones, contralor).subscribe(
        (next: LicenciaModel[]) => {
          this.listaLicencias = next;
          const start = moment();

          this.listaLicencias.forEach(lic => {
            lic.rutTrab = format(lic.rutTrab, true);
            const end = moment(lic.lastTime, 'YYYY-MM-DD');
            lic.diferenciaDias = start.diff(end, 'days');
            if (lic.documentos.length > 0) {
              lic.glosa.documentos = lic.documentos.length;
              lic.documentos.forEach(doc => {
                const fechaDoc = doc.lmId !== '1' ? new Date(parseFloat(doc.timestamp) * 1000) : new Date(doc.timestamp);
                if (fechaDoc > new Date(lic.lastTime)) {
                  /*if (new Date(parseFloat(doc.timestamp) * 1000) > new Date(lic.lastTime)) {*/
                  lic.glosa.nuevos += 1;
                }
              });
            }
          });
          this.datos = new MatTableDataSource<any>(this.listaLicencias);
          this.ordenSeleccionado = {id: true, descripcion: 'Más reciente'};
          this.datos.paginator = this.paginador;
          this.datos.sort = this.sort;

          this.spinner.hide();
        },
        (err: any) => {
          console.log(err);
        }
      );
    } else {
      this.router.navigate(['/login']);
    }
  }

  descargarListado(): void {
    const elementos = [];
    this.listaLicencias.forEach(item => {
      elementos.push({
        folio: item.folio,
        rutTrab : item.rutTrab,
        numServicioSalud: item.numServicioSalud,
        lastDatePendiente: item.lastDatePendiente,
        diferenciaDias : item.diferenciaDias
      });
    });
    this.exportacionService.exportarExcel(elementos, 'Licencias_Pendientes');
  }
}
